import arrayMove from 'array-move';
import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Button, Input } from 'reactstrap';
import { removeChosenSongFromSongbook } from '../../api/collection-relation/actions';
import { openModal } from '../../api/modals/actions';
import { fetchSongbookDetail, modifySongbook } from '../../api/songbooks/actions';
import ModalSongbook from '../../components/modal-songbook';
import SongList from '../../components/song-list';
import SongListItem from '../../components/songbook/song_list_item';
import { SvgEdit, SvgPdf, SvgPlay } from '../../components/svg';
import { Spinner } from '../../utils';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { fetchInstruments } from '../../api/instrument/actions';

let updating = false

const trans = [
  gettext('Back to songbooks'),
  gettext('Custom order'),
  gettext('Order by cover artist name'),
  gettext('Order by title'),
  gettext('Play songbook')
]

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    // width: '400px',
    padding: 0
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)"
  }
};

const SortableList = ({ findItem, onSortEnd, songbook, onTransposeSong, onSetSongCapo, songList, deleteSongFromSongbook, songbookId, favedSongs, props }) => {
  let allSongbooks = <div>
    {songList.map((item, index) => {
      return <SongListItem onSortEnd={onSortEnd} findItem={findItem} songbook={songbook} collectionRelation={item} sortType={songbook.data.sort_type} key={index + "_songbook_" + item.id} index={index} getSongbook={(id) => props.dispatch(fetchSongbookDetail(id, false))} onSetSongCapo={onSetSongCapo} onTransposeSong={onTransposeSong} deleteSongFromSongbook={deleteSongFromSongbook} songbookId={songbookId} favedSongs={favedSongs} user={props.user} dispatch={props.dispatch} />
    })}
  </div>
  return allSongbooks
}

class SongbookDetailContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      songOrder: [],
      songList: [],
      favedSongs: [],
      title_editor: false,
    }

    this.onTransposeSong = this.onTransposeSong.bind(this)
    this.onSetSongCapo = this.onSetSongCapo.bind(this)
    this.deleteSongFromSongbook = this.deleteSongFromSongbook.bind(this)
    this.selectSortType = this.selectSortType.bind(this)
  }

  componentDidMount() {
    this.props.dispatch(fetchInstruments())
    this.props.dispatch(fetchSongbookDetail(this.props.match.params.code, this.props.location.state && this.props.location.state.onlyPublic))
  }

  componentDidUpdate(prevProps) {
    if ((JSON.stringify(prevProps.songbook.data) !== JSON.stringify(this.props.songbook.data) && this.props.songbook.data.songs) || (this.state.songList.length === 0 && !this.props.songbook.isFetching)) {
      if (this.props.songbook.data.sort_type === "custom") {
        this.setState({
          songOrder: this.props.songbook.data.songs.sort((a, b) => a.order - b.order).map(item => item.song.id),
          songList: this.props.songbook.data.songs,
        })
      } else {
        this.setState({
          songOrder: this.props.songbook.data.songs.map((item) => item.song.id),
          songList: this.props.songbook.data.songs,
        })
      }
    }

    // Favorites retrieved

    if (this.props.favourites.fetched && this.props.favourites.data.results && JSON.stringify(prevProps.favourites) !== JSON.stringify(this.props.favourites)) {
      let fav = this.props.favourites.data.results.map(song => song.id)
      this.setState({ favedSongs: fav })
    }
  }

  renderUnorderedSonglist(songbookId) {
    let isPublic = this.props.location.state && this.props.location.state.isPublic;
    let unorderedSongbook = this.props.songbook.data.songs.map((item, index) => {
      return <SongListItem songbook={this.props.songbook} collectionRelation={item} owner={this.props.songbook.data.owner} sortType={this.props.songbook.data.sort_type} key={index + "_songbook_" + item.id} index={index} getSongbook={(id) => this.props.dispatch(fetchSongbookDetail(id, isPublic))} onSetSongCapo={this.onSetSongCapo} onTransposeSong={this.onTransposeSong} deleteSongFromSongbook={this.deleteSongFromSongbook} songbookId={songbookId} isPublic={isPublic} favedSongs={this.state.favedSongs} user={this.props.user} dispatch={this.props.dispatch} />
    })
    return unorderedSongbook;
  }

  onSortEnd({ oldIndex, newIndex }) {
    let newSongOrder = arrayMove(this.state.songOrder, oldIndex, newIndex)
    let newSongListOrder = arrayMove(this.state.songList, oldIndex, newIndex)
    this.setState(() => ({
      songOrder: newSongOrder,
      songList: newSongListOrder,
    }));
    this.props.dispatch(modifySongbook(this.props.songbook.data.id, { 'ordering': newSongOrder }));
  }

  onSortMove(e) {
    if (e.screenY + 100 > window.screen.height && !updating) {
      window.scrollTo({ top: e.pageY + 300, behavior: "smooth" })
      updating = true
      setTimeout(() => { updating = false }, 300)
    } else if (e.screenY - 250 < 0 && !updating) {
      window.scrollTo({ top: e.pageY - 300, behavior: "smooth" })
      updating = true
      setTimeout(() => { updating = false }, 300)
    }
  }

  findItem(id) {
    const card = this.state.songList.filter((c) => c.id === id)[0]
    return {
      card,
      index: this.state.songList.indexOf(card),
    }
  }

  onSetSongCapo(crId, value) {
    this.props.dispatch(modifySongbook(this.props.songbook.data.id, { 'cr_id': crId, 'new_capo': value }));
  }

  onTransposeSong(id, value, crId) {
    this.props.dispatch(modifySongbook(this.props.songbook.data.id, { 'cr_id': crId, 'new_key': value }));
  }

  deleteSongFromSongbook(relationId, songbookId) {
    let songbookDetail = true;
    this.props.dispatch(removeChosenSongFromSongbook(relationId, songbookId, songbookDetail));
  }

  selectSortType(e) {
    this.props.dispatch(modifySongbook(this.props.songbook.data.id, { 'sort_type': e.currentTarget.value }));
  }

  saveTitle(e) {
    e.preventDefault();
    if (e.target[0].value.trim() !== "") {
      this.props.dispatch(modifySongbook(this.props.songbook.data.id, { 'title': e.target[0].value }));
    }
    this.setState({ title_editor: false })
  }

  open_title_editor() {
    this.setState({ title_editor: true })
  }


  render() {
    const { songbook } = this.props
    let isPublic = 1;

    return (
      <div className={`container-fluid ${!this.props.user.hasSubscription && ' toplists'}`}>
        {this.props.user.hasSubscription ?
          <><div className="row justify-content-center">
            <div className="col-15 col-xl-8 p-0">
              <h1 className="songbook-title">{songbook.data.title}</h1>
              {this.props.user.username === songbook.data.owner &&
                <div className="back-btn"><Link to="/songbooks/">&larr; {gettext('Back to songbooks')}</Link></div>
              }
            </div>
          </div>
            <div className="row justify-content-center">
              <div className="col-15 col-xl-8">
                {songbook.data.songs !== undefined &&
                  <div className="songbook-detail">
                    <div className="row">
                      <div className="songbook-head song row align-items-center">
                        {this.props.user.username === songbook.data.owner ?
                          <>
                            {this.state.title_editor ?
                              <div className="col-md-9 col-16">
                                <form className="songbook-title" onSubmit={(e) => this.saveTitle(e)}>
                                  <Input type="text" name="title" label="Title" defaultValue={songbook.data.title} />
                                  <Input type="submit" name="Save" value={gettext('Save')} />
                                </form>
                              </div>
                              :
                              <>
                                <div className="col-md-9 col-16 white">
                                  <h3 className="songbook-title">{songbook.data.title}</h3>
                                  <button onClick={() => this.open_title_editor()}><SvgEdit /></button>
                                </div>
                              </>
                            }
                          </>
                          :
                          <div className="col-md-9 col-16">
                            <h3 className="songbook-title">{songbook.data.title}</h3>
                          </div>
                        }
                        {this.props.songbook.isFetching &&
                          <Spinner />
                        }
                        <div className="col-md-3 col-8">
                          <Button color="light">
                            {this.props.user.hasSubscription ?
                              <a title={gettext("Play songbook")} href={`/songbooks/${songbook.data.id}/play/`} >
                                <SvgPlay />
                              </a>
                              :
                              <a title={gettext("Play songbook")} onClick={() => this.props.dispatch(openModal("modal-paywall", "play-songbook"))}>
                                <SvgPlay />
                              </a>
                            }
                          </Button>
                          {this.props.user.isAuthenticated && this.props.user.hasSubscription ?
                            <ModalSongbook showButton={true} icon={<SvgPdf />} style={customStyles} user={this.props.user} songbook={songbook.data}></ModalSongbook>
                            :
                            <button className="modal-opener btn button-modal-songbook" onClick={() => this.props.dispatch(openModal("modal-paywall", "pdf-songbook"))}><span className="text"></span> <span className="icon"><SvgPdf /></span></button>
                          }
                        </div>
                        {this.props.user.username === songbook.data.owner &&
                          <div className="col-md-4 col-8 white">

                            <div className="songbook-filter">
                              <Input type="select" name="select" className="sort-selector col-3" onChange={(e) => this.selectSortType(e)} value={songbook.data.sort_type}>
                                <option value="title">{gettext('Order by title')}</option>
                                <option value="author">{gettext('Order by cover artist name')}</option>
                                <option value="custom">{gettext('Custom order')}</option>
                              </Input>
                            </div>
                          </div>
                        }
                      </div>
                      <div className="list w-100">
                        <div className="song row align-items-center w-100 header">
                          <div className="col-md-5 col-sm-8 col-7 title-col">{gettext('Song title')}</div>
                          <div className="col-sm-2 col-2 tones">{gettext('Capo')}</div>
                          <div className="col-sm-2 col-2 tones">{gettext('Key')}</div>
                          <div className="col-md-4 col-4 d-none d-md-block chords-col">{gettext('Chords')}</div>
                          <div className="col-lg-2 col-sm-3 col-5 actions d-flex justify-content-between align-items-center">&nbsp;</div>
                        </div>
                        {songbook.data.sort_type === 'custom' &&
<>
                          <DndProvider backend={HTML5Backend}>
                            <SortableList
                              props={this.props}
                              items={this.state.songOrder}
                              findItem={this.findItem.bind(this)}
                              onSortEnd={this.onSortEnd.bind(this)}
                              onSortMove={this.onSortMove.bind(this)}
                              songbook={songbook}
                              onTransposeSong={this.onTransposeSong}
                              onSetSongCapo={this.onSetSongCapo}
                              songList={this.state.songList}
                              deleteSongFromSongbook={this.deleteSongFromSongbook}
                              songbookId={songbook.data.id}
                              favedSongs={this.state.favedSongs} />
                          </DndProvider>
                          </>
                        }
                        {(songbook.data.sort_type === 'title' || songbook.data.sort_type === 'author') && this.renderUnorderedSonglist(songbook.data.id)}
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </>
          :
          <>
            <div className="row justify-content-center top-list-row">
              <div className="col-lg-10 col-xl-8 col-md-12 col-15">
                <h1>{gettext('Songbooks')}</h1>
              </div>
            </div>
            <div className="row justify-content-center songbook-name">
              <div className="col-md-9 col-16">
                <h3 className="songbook-title">{songbook.data.title}</h3>
              </div>
              <div className="col-md-3 col-8">
                <Button color="light">
                  {this.props.user.hasSubscription ?
                    <a title={gettext("Play songbook")} href={`/songbooks/${songbook.data.id}/play/`} >
                      <SvgPlay />
                    </a>
                    :
                    <a title={gettext("Play songbook")} onClick={() => this.props.dispatch(openModal("modal-paywall", "play-songbook"))}>
                      <SvgPlay />
                    </a>
                  }
                </Button>
                {this.props.user.isAuthenticated && this.props.user.hasSubscription ?
                  <ModalSongbook showButton={true} icon={<SvgPdf />} style={customStyles} user={this.props.user} songbook={songbook.data}></ModalSongbook>
                  :
                  <button className="modal-opener btn button-modal-songbook" onClick={() => this.props.dispatch(openModal("modal-paywall", "pdf-songbook"))}><span className="text"></span> <span className="icon"><SvgPdf /></span></button>
                }
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-10 col-xl-8 col-md-12 col-15">
                <SongList list={this.state.songList} allFavourites={false} showExcerpt={true} toplistType="songbooks" showFilterInfo={false} />
              </div>
            </div>
          </>
        }
      </div>
    )
  }
}

SongbookDetailContainer.defaultProps = {
}

function mapStateToProps(state) {

  var f = {
    songbook: {},
    user: {},
    favourites: {}
  }
  f.songbook = (state.songbook === undefined) ? {} : state.songbook
  f.user = (state.user === undefined) ? {} : state.user
  f.favourites = (state.favourites === undefined) ? {} : state.favourites
  return f;
}

export default connect(mapStateToProps)(withRouter(SongbookDetailContainer));