import $ from 'jquery';
import React from 'react';
import { selectInstrument } from '../../api/instrument/actions';
import { saveProfile } from '../../api/profile/actions';
import { fetchRelatedSongs } from '../../api/related-songs/actions';
import { modifySongbook } from '../../api/songbooks/actions';
import { setKey } from '../../api/song/actions';
import { DEFAULT_INSTRUMENT, FONT_SIZES } from '../../constants';
import { store } from '../../store';
import { connectWithStore } from '../../utils';
import Adsense from '../adsense';
import Autoscroll from '../autoscroll';
import Chord from '../chord';
import ChordColorSelector from '../chord-color-selector';
import CountdownTimer from '../countdown-timer';
import DarkmodeSelector from '../darkmode';
import Favourite from '../favourite';
import InstrumentSelector from '../instrument-selector';
import RelatedSongs from '../related-songs';
import AddSongToSongbooks from '../song-add-to-songbook';
import SongShowChords from '../song-show-chords';
import SongLayout from '../song-layout';
import SongSizeSelector from '../song-size-selector';
import Transpose, { calculateCapoPositionForOriginalKey } from '../song-transpose';
import { SvgPrev, SvgSettings } from '../svg';
import ShareQRCode from '../song-share-qr';
import { openModal } from '../../api/modals/actions';


let trans = [
    gettext("Unknown"),
    gettext("Melody author"),
    gettext("Lyrics author"),
    gettext("Performed by"),
    gettext("Settings"),
    gettext("Instrument"),
    gettext("Theme"),
    gettext("Font size"),
    gettext("Chord color"),
    gettext('Show chords'),
    gettext('Hide chords'),
    gettext("Close"),
    gettext("Chord key"),
    gettext('Previous song'),
    gettext('Next song'),
    gettext('Related songs'),
    gettext("[Am]A valid subscription is [F]required to [C]export to OnSong & [G]similar apps"),
    interpolate(ngettext('Capo on fret %s for original key', 'Capo on fret %s for original key', 1), [1]),
    interpolate(ngettext('Hint: To play in the original key with these chords, put your capo on fret %s', 'Hint: To play in the original key with these chords, put your capo on fret %s', 1), [1]),
    gettext('(Capo override from your songbook)'),
    gettext('Back to songbook'),
]

class Song extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentScroll: 0,
            currentKey: "C",
            favedSongs: [],
            song: undefined,
            timerDivVisible: false,
            tooltip: {
                on: false,
                target: null,
            }
        }
        this.songRef = React.createRef()
        this.handleScroll = this.handleScroll.bind(this);
        this.cancelSkipToNextSong = this.cancelSkipToNextSong.bind(this);
        this.renderTooltip = this.renderTooltip.bind(this);


    }

    componentDidMount() {
        $(".song-controls .btn, .save-btn").click(function () {
            $("body").toggleClass("song-controls-open")
        })

        $(document).click((e) => {
            if ($('body').hasClass('song-controls-open') && !($.contains($('.song-controls')[0], e.target) || $.contains($('.btn-song-controls')[0], e.target) || ($('.modal-paywall').length > 0 && $.contains($('.modal-paywall')[0], e.target)))) {
                $('body').removeClass('song-controls-open')
            }
        })

        window.addEventListener('scroll', this.handleScroll);

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentWillUpdate() {
        $('.open-chords').unbind('mouseenter')
        $('.open-chords').unbind('mouseleave')
    }

    componentDidUpdate(prevProps, prevState) {
        window.init_vextab()

        if (prevState.song === undefined && this.props.song.id !== undefined || prevState.song !== undefined && prevState.song.id !== this.props.song.id) {
            const { song, songIndex } = this.props;
            let newState = {
                song,
                currentKey: song.transposed_to,
            }
            if (this.props.songbook && this.props.songbook.data && this.props.songbook.data.songs && this.props.songbook.data.songs[songIndex].scroll_speed) {
                newState.currentScroll = this.props.songbook.data.songs[songIndex].scroll_speed
            }
            this.setState(newState);
            if (window.Intercom) {
                window.Intercom('trackEvent', 'visited-song', {
                    song_id: song.id,
                    url: window.location.href,
                });
            }
        }

        if (Object.keys(prevProps.song).length == 0 && Object.keys(this.props.song).length > 0) {
            this.props.dispatch(fetchRelatedSongs(this.props.song.id))
        }

        // Favorites retrieved
        if (JSON.stringify(prevProps.favourites) !== JSON.stringify(this.props.favourites)) {
            let fav = this.props.favourites.data.results.map(song => song.id)
            this.setState({ favedSongs: fav })
        }

        $(".open-chords").mouseenter((e) => {
            this.setState({
                tooltip: {
                    on: true,
                    target: e.currentTarget
                }
            })
        })
        $(".open-chords").mouseleave((e) => {
            this.setState({
                tooltip: {
                    on: false,
                    target: null
                }
            })
        })

    }

    handleScroll() {
        if (this.props.songbook) {
            if ($(window).width() > 768) {
                var desktop_el_top = $('footer').offset().top + 230;
                var desktop_el_bottom = $('footer').offset().top + 230 + $('footer').outerHeight();
                var desktop_screen_bottom = $(window).scrollTop() + $(window).innerHeight();
                var desktop_screen_top = $(window).scrollTop();

                if ((desktop_screen_bottom > desktop_el_top) && (desktop_screen_top < desktop_el_bottom)) {
                    this.setState({ timerDivVisible: true })
                } else {
                    this.setState({ timerDivVisible: false })
                }
            } else {
                var mobile_el_top = $('footer').offset().top - 100;
                var mobile_el_bottom = $('footer').offset().top - 100 + $('footer').outerHeight();
                var mobile_screen_bottom = $(window).scrollTop() + $(window).innerHeight();
                var mobile_screen_top = $(window).scrollTop();

                if ((mobile_screen_bottom > mobile_el_top) && (mobile_screen_top < mobile_el_bottom)) {
                    this.setState({ timerDivVisible: true })
                } else {
                    this.setState({ timerDivVisible: false })
                }
            }
        }
    }

    //Callback function from ChordColorSelector
    changeChordColor(color) {
        this.props.dispatch(saveProfile('chordColor', color, this.props.user.isAuthenticated))
    }

    //Callback function from SongSizeSelector
    changeFontSize(size) {
        this.props.dispatch(saveProfile('fontSize', size, this.props.user.isAuthenticated))
    }

    //Callback function from ShowChords
    toggleChords(value) {
        this.props.dispatch(saveProfile('showChords', value, this.props.user.isAuthenticated))
    }

    toggleChordCradle(value) {
        this.props.dispatch(saveProfile('showChordCradle', !this.props.showChordCradle, this.props.user.isAuthenticated))
    }

    //Callback function from ShowChordImages
    changeSongLayout(value) {
        this.props.dispatch(saveProfile('songLayout', value, this.props.user.isAuthenticated))
    }

    changeDarkmode(value) {
        let darkMode = value === 'dark' ? true : false
        this.props.dispatch(saveProfile('darkMode', darkMode, this.props.user.isAuthenticated))
    }

    changeInstrument(song, e) {
        let value = e.currentTarget.value
        this.props.dispatch(saveProfile('instrument_preference', value, this.props.user.isAuthenticated))
        this.props.dispatch(selectInstrument(song.id, song.transposed_to, value))
    }


    // Callback function from Autoscroll
    setScroll(value) {
        if (this.props.songbook && this.props.songbook.data && this.props.songbook.data.id) {
            this.props.dispatch(modifySongbook(this.props.songbook.data.id, { 'song_id': this.props.song.id, 'scroll_speed': value }))
        }
    }

    // Callback function from Transposer
    setKey(id, key) {
        this.setState({ currentKey: key });
        this.props.dispatch(setKey(id, key));
    }

    renderArtists(type) {
        const { song } = this.props
        if (song.authors) {
            var artists = song.authors.filter(author => author.authorship_type == type)
            if (artists.length > 0) {
                return artists.map((artist, i) => <React.Fragment key={i}><a href={`/artists/${artist.author.slug}`}>{artist.author.name}</a>, </React.Fragment>)
            }
            return gettext("Unknown")
        }
    }

    goToNextSong() {
        this.props.nextSong();
        this.songRef.current.scrollTo(0, 0);
        $('.song-controls').addClass('collapsed').attr('aria-expanded', 'false')
    }

    goToPrevSong() {
        this.props.prevSong();
        this.songRef.current.scrollTo(0, 0);
        $('.song-controls').addClass('collapsed').attr('aria-expanded', 'false')
    }

    getNextSongTitle(songs, songIndex) {
        let idx = songIndex + 1
        let title = ''
        if (songs) {
            if (idx >= songs.length) {
                idx = 0
            }
            title = songs[idx].song.title
        }
        return title;
    }

    cancelSkipToNextSong() {
        this.setState({ timerDivVisible: false })
    }

    renderTooltip(currentInstrument, instrument_preference, song) {
        let element = this.state.tooltip.target

        if (element) {
            let rect = element.getBoundingClientRect()

            let chordName = element.textContent.replace('[', '').replace(']', '');

            let chord = song.chords.filter((chord) => chord.name === chordName)[0]
            let width = 80
            let height = 115
            let yOffset = 10

            const styles = {
                position: 'absolute',
                top: window.scrollY + rect.y - (yOffset + height),
                left: window.scrollX + rect.x + (rect.width / 2) - width / 2
            }

            return <div className={`gp-tooltip chord-color-${this.props.chordColor}`} style={styles}>
                <Chord chord={chord} fontSize={this.props.fontSize} instrument={currentInstrument ? currentInstrument.safe_name : ""} instrId={instrument_preference} chordColor={this.props.chordColor} songId={song.id} />
            </div>

        }
    }

    render() {
        const { song, instruments, instrument_preference } = this.props

        const currentInstrument = instruments.find(i => i.id === instrument_preference)

        let collectionRelation = null
        if (this.props.playSongbook && this.props.playSongbook.songs && this.props.playSongbook.songs.length > 0) {
            collectionRelation = this.props.collectionRelation
        }

        let capoFret = 0
        let capoExtraMessage = ''
        if (song.capo > 0) {
            capoFret = song.capo
        }

        if (collectionRelation && collectionRelation.capo && collectionRelation.capo !== capoFret) {
            capoFret = collectionRelation.capo
            capoExtraMessage = gettext('(Capo override from your songbook)')
        }

        let songLayoutClasses = "col-xxl-5 col-lg-6 col-md-9 col-16 song-wrapper"
        if (this.props.songLayout === '2col') {
            songLayoutClasses = "col-xxl-8 col-lg-10 col-md-14 col-16 song-wrapper columns-2"
        }

        let isCurrentKeySameAsOriginal = this.state.currentKey === song.key
        if (song.key) {
            let parts1 = this.state.currentKey.split('/')
            let parts2 = song.key.split('/')
            let parts1NoMinor = parts1[0].replace('m', '')
            let parts2NoMinor = parts2[0].replace('m', '')
            if (parts1NoMinor === parts2NoMinor) {
                isCurrentKeySameAsOriginal = true
            }
        }

        return (
            <>
                <div className="container-fluid">
                    {this.state.tooltip && this.state.tooltip.on &&
                        <>
                            {this.renderTooltip(currentInstrument, instrument_preference, song)}
                        </>
                    }
                    {false && this.props.user && !this.props.user.hasSubscription &&
                        <div className="row justify-content-center align-items-center">
                            <div className="col-16 text-center">
                                <Adsense
                                    client="ca-pub-3801256823535192"
                                    slot="5228210178"
                                    style={{ width: 728, height: 90, display: "inline-block" }}
                                    layout="fixed"
                                />
                            </div>
                        </div>
                    }
                    <div className="row justify-content-center align-items-center about-song-container" ref={this.songRef}>
                        <div className="col-xxl-8 col-lg-10 col-md-15 offset-lg-0 offset-sm-0 offset-md-1 col-16 title-container">
                            <h2 className="title">
                                {song.title}
                                {this.props.playSongbook && <span><a href={`/songbooks/${this.props.playSongbook.id}`}>{gettext('Back to songbook')}</a></span>}
                            </h2>
                            <div className="like-container">
                                <div className="favourites">
                                    <Favourite id={song.id} favouriteIds={this.state.favedSongs} user={this.props.user} dispatch={this.props.dispatch} />
                                </div>
                                <div className="add-to-songbooks-outer-container">
                                    <AddSongToSongbooks
                                        ids={[song.id]}
                                        currentKey={this.state.currentKey}
                                        multipleSongs={false}
                                    />
                                </div>
                                {this.props.playSongbook &&
                                    <div className='qr-outer-container'>
                                        <ShareQRCode
                                            user={this.props.user}
                                            dispatch={this.props.dispatch}>
                                        </ShareQRCode>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mb-3">
                        <div className="col-xxl-8 col-lg-10 col-md-14 col-16 subtitle-container">
                            <ul className="artists list-inline">
                                <li className="list-inline-item">{gettext("Melody author")}: {this.renderArtists("melody")}</li>
                                <li className="list-inline-item">{gettext("Lyrics author")}: {this.renderArtists("lyrics")}</li>
                                <li className="list-inline-item">{gettext("Performed by")}: {this.renderArtists("cover")}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row justify-content-center settings-bar-container">
                        <div className="col-xxl-8 col-lg-10 col-md-14 col-16">
                            <div className={`row settings-bar mx-0 ${this.props.playSongbook ? "playsongbook-upper" : ""}`}>
                                <div className="col-4 col-sm-3 song-controls">
                                    <button className="btn btn-icon">
                                        <div className="svg-container settings"><SvgSettings /></div>
                                        <p className="d-none d-md-block">{gettext("Settings")}</p>
                                    </button>
                                    <div className="song-controls">
                                        <div className="control">
                                            <p>{gettext("Instrument")}</p>
                                            <InstrumentSelector onChange={this.changeInstrument.bind(this, song)} instruments={instruments} instrumentPreference={instrument_preference} />
                                        </div>
                                        <div className="control view-mode">
                                            <p>{gettext("Theme")}</p>
                                            <DarkmodeSelector onChange={this.changeDarkmode.bind(this)} darkMode={this.props.darkMode} user={this.props.user} dispatch={this.props.dispatch} />
                                        </div>
                                        <div className="control song-size-selector">
                                            <p>{gettext("Font size")}</p>
                                            <SongSizeSelector fontSize={this.props.fontSize} onChange={this.changeFontSize.bind(this)} user={this.props.user} dispatch={this.props.dispatch} />
                                        </div>
                                        <div className="control">
                                            <p>{gettext("Chord color")}</p>
                                            <ChordColorSelector chordColor={this.props.chordColor} onChange={this.changeChordColor.bind(this)} user={this.props.user} dispatch={this.props.dispatch} />
                                        </div>
                                        <div className="save-btn btn-song-controls">
                                            <button>{gettext("Close")}</button>
                                        </div>
                                    </div>
                                </div>
                                <div className={`col-4 col-sm-3 song-controls chords ${this.props.showChords ? 'active' : ''}`}>
                                    <SongShowChords showChords={this.props.showChords} onChange={this.toggleChords.bind(this)} />
                                </div>
                                <div className="col-4 col-sm-3 song-controls tones">
                                    <Transpose heading={gettext("Chord key")} setKey={this.setKey.bind(this)} currentKey={this.state.currentKey} originalKey={song.key} id={song.id} user={this.props.user} dispatch={this.props.dispatch} type={"single-song"} />
                                </div>
                                <div className="col-4 col-sm-3 song-controls scroll">
                                    <Autoscroll user={this.props.user} dispatch={this.props.dispatch} setScroll={this.setScroll.bind(this)} defaultScroll={this.state.currentScroll} />
                                </div>
                                <div className={`d-none d-sm-block col-4 song-controls chords`}>
                                    <SongLayout user={this.props.user} dispatch={this.props.dispatch} songLayout={this.props.songLayout} onChange={this.changeSongLayout.bind(this)} />
                                </div>
                            </div>
                            {this.props.playSongbook && this.props.playSongbook.songs && this.props.playSongbook.songs.length > 0 &&
                                <div className="row settings-bar mx-0 playsongbook-lower">
                                    <div className="col-4 song-controls play" onClick={this.goToPrevSong.bind(this)}>{gettext('Previous song')}</div>
                                    <div className="col-8 song-controls song-list flex-column" data-toggle="collapse" href="#show-list" role="button" aria-expanded="false" aria-controls="show"><span>{this.props.playSongbook.title}:</span>
                                        <ul className="playsongbook-songs">
                                            {this.props.playSongbook.songs.map((s, i) => {
                                                return (<li id="show-list" className={s.song.id === song.id ? "active" : " collapse do-show"} onClick={() => this.props.setSong(i)}>{s.song.title}</li>)
                                            })}
                                        </ul>
                                    </div>
                                    <div className="col-4 song-controls play" onClick={this.goToNextSong.bind(this)}>{gettext('Next song')}</div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={`row justify-content-center song-container lang-${window.LANGUAGE_CODE} ${this.props.playSongbook ? "playsongbook" : ""}`}>
                        {false && this.props.user && !this.props.user.hasSubscription &&
                            <div className="col-xxl-4 col-lg-3 d-none d-lg-block text-center">
                                <Adsense
                                    client="ca-pub-3801256823535192"
                                    slot="4046990443"
                                    style={{ width: 160, height: 600, display: "inline-block" }}
                                    layout="fixed"
                                />
                            </div>
                        }
                        <div className={songLayoutClasses}>
                            {(!isCurrentKeySameAsOriginal && song.key !== null && song.key !== undefined) ?
                                <>
                                    {calculateCapoPositionForOriginalKey(song.key, this.state.currentKey, capoFret) !== 0 &&
                                        <div className={`mb-5 song col font-size-${FONT_SIZES[this.props.fontSize]}`}>
                                            {interpolate(ngettext('Hint: To play in the original key with these chords, put your capo on fret %s', 'Hint: To play in the original key with these chords, put your capo on fret %s', calculateCapoPositionForOriginalKey(song.key, this.state.currentKey, capoFret)), [calculateCapoPositionForOriginalKey(song.key, this.state.currentKey, capoFret)])}
                                        </div>
                                    }
                                </>
                                :
                                <>
                                    {capoFret > 0 &&
                                        <div className={`mb-5 song col font-size-${FONT_SIZES[this.props.fontSize]}`}>{interpolate(ngettext('Capo on fret %s for original key', 'Capo on fret %s for original key', capoFret), [capoFret])} {capoExtraMessage}</div>
                                    }
                                </>
                            }
                            <div className={`song col chords-${!!this.props.showChords} chord-color-${this.props.chordColor} font-size-${FONT_SIZES[this.props.fontSize]}`} dangerouslySetInnerHTML={{ __html: song.body_chordified }}></div>
                            {song && song.body_chordified &&
                                <pre style={{ display: "none" }} dangerouslySetInnerHTML={{ __html: this.props.user.hasSubscription ? song.song : song.title + "\nKey: Am\n" + gettext("[Am]A valid subscription is [F]required to [C]export to OnSong and [G]similar apps") }} />
                            }
                        </div>
                        {this.props.songLayout === '1col' ?
                            <div className={`col-xxl-3 col-lg-4 col-md-5 col-16 chords-wrapper chord-color-${this.props.chordColor}`}>
                                {this.props.showChords > 0.5 &&
                                    <div className={`chord-container chord-color-${this.props.chordColor} ${this.props.user && this.props.user.hasSubscription && "user-pref"}`}>
                                        {Object.keys(song).length > 0 &&
                                            song.chords.map((chord) => {
                                                return <Chord chord={chord} fontSize={this.props.fontSize} instrument={currentInstrument ? currentInstrument.safe_name : ""} instrId={instrument_preference} chordColor={this.props.chordColor} songId={song.id} />
                                            })
                                        }
                                        {song.lesson &&
                                            <>
                                                <div className="top-text">{gettext('Learn this song!')}</div>
                                                {this.props.user.hasSubscription ?

                                                    <a href={`/lessons/${song.lesson.slug}`}>
                                                        <div className="image-container position-relative">
                                                            <img width="100%" src={window.IMAGES.lesson_thumbnail} />
                                                            <div className="overlay position-absolute d-flex align-items-center justify-content-center">
                                                                <span className="thumb-text">{gettext('Go to lesson')}</span>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    :
                                                    <a onClick={() => this.props.dispatch(openModal("modal-paywall", "play-songbook"))}>
                                                        <div className="image-container position-relative">
                                                            <img width="100%" src={window.IMAGES.lesson_thumbnail} />
                                                            <div className="overlay position-absolute d-flex align-items-center justify-content-center">
                                                                <span className="thumb-text">{gettext('Go to lesson')}</span>
                                                            </div>
                                                        </div>
                                                    </a>
                                                }
                                            </>
                                        }
                                    </div>
                                }
                            </div>
                            :
                            <>
                                {this.props.showChords &&
                                    <div className={`chords-fixed d-flex justify-content-center chords-wrapper chord-color-${this.props.chordColor} chord-cradle chord-cradle-${this.props.showChordCradle ? 'show' : 'hide'}`}>
                                        <div className={`col-xxl-8 col-lg-10 col-md-14 col-16 chord-container chord-color-${this.props.chordColor} ${this.props.user && this.props.user.hasSubscription && "user-pref"}`}>
                                            <div className="chord-cradle-toggler" onClick={this.toggleChordCradle.bind(this)} title={this.props.showChordCradle ? gettext('Hide chords') : gettext('Show chords')}>
                                                <SvgPrev />
                                            </div>
                                            {Object.keys(song).length > 0 &&
                                                song.chords.map((chord) => {
                                                    return <Chord chord={chord} fontSize={this.props.fontSize} instrument={currentInstrument ? currentInstrument.safe_name : ""} instrId={instrument_preference} chordColor={this.props.chordColor} songId={song.id} />
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                            </>
                        }
                        {false && this.props.user && !this.props.user.hasSubscription &&
                            <div className="col-xxl-4 col-lg-3 d-none d-lg-block text-center">
                                <Adsense
                                    client="ca-pub-3801256823535192"
                                    slot="9654540341"
                                    style={{ width: 160, height: 600, display: "inline-block" }}
                                    layout="fixed"
                                />
                            </div>
                        }
                    </div>
                    {song.tags && song.tags.length > 0 &&
                        <div className="row justify-content-center d-flex">
                            <div className="col-xxl-8 col-lg-10 col-md-14 col-16 tag-container">
                                {song.tags.map(tag =>
                                    <a className="tag-container--tag" href={`/tag/${tag.slug}`}>{tag.name}</a>
                                )}
                            </div>
                        </div>
                    }
                    {this.props.user && !this.props.user.hasSubscription &&
                        <div className="row adsense-bottom">
                            <div className="col-16 text-center">
                                <Adsense
                                    client="ca-pub-3801256823535192"
                                    slot="4317425512"
                                    style={{ display: "block" }}
                                    format="auto"
                                    response="true"
                                />
                            </div>
                        </div>
                    }
                </div >
                <div className={this.state.timerDivVisible === true ? 'timer-visible' : 'not-visible'}>
                    {this.state.timerDivVisible && this.props.songbook.data.songs && <CountdownTimer active={this.state.timerDivVisible} nextSong={this.props.nextSong} cancelSkipToNextSong={this.cancelSkipToNextSong} nextSongTitle={this.getNextSongTitle(this.props.playSongbook.songs, this.props.songIndex)} song={this.props.song} />}
                </div>

                {this.props.song && <RelatedSongs relatedSongs={this.props.relatedSongs} />}
            </>
        )
    }
}

Song.defaultProps = {

    fontSize: 2,
    chordColor: "blue",
    showChords: true,
    darkMode: false,
    a: DEFAULT_INSTRUMENT,
    instruments: [],
    playSongbook: null
}

function mapStateToProps(state) {
    var f = {
        favourites: {},
        user: {},
        songbook: {},
        relatedSongs: {},
    }
    f.user = (state.user === undefined) ? [] : state.user
    f.favourites = (state.favourites === undefined) ? [] : state.favourites
    f.songbook = (state.songbook === undefined) ? [] : state.songbook
    f.relatedSongs = (state.relatedSongs === undefined) ? [] : state.relatedSongs
    return f;
}

// export default connect(mapStateToProps)(Song);
export default connectWithStore(store, Song, mapStateToProps);
