import React from 'react';
import GuitarpartyModal from '../modal'
import { closeModal } from '../../api/modals/actions'
import { requestUserDelete, isUserScheduledForDelete, removeUserDelete } from '../../api/user/actions'
import { store } from '../../store'
import { connectWithStore } from '../../utils'
import { remove } from 'js-cookie';


class ModalDeleteAccount extends React.Component {
    constructor() {
        super();
        this.state = {
            usernameInput: '',
        }

        this.onChange = this.onChange.bind(this)
        this.undoScheduledDelete = this.undoScheduledDelete.bind(this)
    }

    componentDidMount() {
        this.props.dispatch(isUserScheduledForDelete())
    }


    onChange(e) {
        const { id, value } = e.target;
        this.setState({
            [id]: value
        })
    }

    deleteAccount() {
        if (this.state.usernameInput === this.props.user.username) {
            this.setState({
                error: ''
            })
            this.props.dispatch(requestUserDelete())
        } else {
            this.setState({
                error: 'The input does not match your username'
            })
        }
    }

    undoScheduledDelete(user){
        let id = user.user
        this.props.dispatch(removeUserDelete(id))
    }

    render() {
        const {user} = this.props

        return (
            <GuitarpartyModal extraClasses="modal-delete-account" modalKey="modal-delete-account"  {...this.props}>
                <div className="text-container">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.props.dispatch(closeModal("modal-delete-account"))}>
                        <span></span>
                        <span></span>
                    </button>
                    <h1>{gettext('Delete account')}</h1>
                    <div className="row justify-content-center flex-column mx-auto w-100">
                        {((user.user_delete && user.user_delete.count > 0)) ?
                            <div className="undo-container d-flex flex-column">{gettext('This user had been scheduled for delete')} <button className="btn" onClick={() => this.undoScheduledDelete(user.user_delete.results[0])}>{gettext("Undo")}</button></div> :
                            <>
                                <label htmlFor="usernameInput">{gettext('Enter your username to confirm')}</label>
                                <input id="usernameInput" type="text" onChange={(e) => this.onChange(e)} />
                                <button className="delete-btn" onClick={this.deleteAccount.bind(this)}>{gettext('Confirm')}</button>
                                {this.state.error !== '' && <div>{this.state.error}</div>}
                            </>}
                    </div>
                </div>
            </GuitarpartyModal>
        )
    }
}


function mapStateToProps(state) {
    var f = {
        modals: {},
        user: {},
    }

    f.modals = (state.modals === undefined) ? [] : state.modals
    f.user = (state.user === undefined) ? [] : state.user

    return f;
}

export default connectWithStore(store, ModalDeleteAccount, mapStateToProps);