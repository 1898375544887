import React from 'react';

import InstrumentSelector from '../instrument-selector';
import DarkmodeSelector from '../darkmode';
import moment from 'moment';

import { openModal } from '../../api/modals/actions';
import { cancel } from '../../api/subscription/actions'
import { fetchUser, userUpdateInfo } from '../../api/user/actions'
import { checkedFavourite } from '../../api/favourite/actions';
import ModalReceipt from '../modal-receipt'

let trans = [
  gettext("Billing type"),
  gettext("Total"),
  gettext("Date"),
  gettext("Receipt"),
  gettext("Transactions"),
  gettext("Card holder name"),
  gettext("Card"),
  gettext("Valid until"),
  gettext("trial"),
  gettext("renewal"),
  gettext("Stop renewal"),
  gettext("Subscription is cancelled and expires"),
]


class Subscription extends React.Component {

    constructor(props){
        super(props)
        this.state = {}

        this.cancelSubscription = this.cancelSubscription.bind(this)
        this.renderBillingLogsTable = this.renderBillingLogsTable.bind(this)
        this.openReceipt = this.openReceipt.bind(this)
        this.getPaymentMethod = this.getPaymentMethod.bind(this)
    }

    cancelSubscription() {
      if (window.confirm(gettext("Are you sure you want to cancel your subscription?"))) {
        Promise.resolve(this.props.dispatch(cancel()))
        .then(() => {
            this.props.dispatch(fetchUser())
        })
      }
    }

    openReceipt(transaction){
      this.props.dispatch(openModal("modal-receipt", "scroll", transaction.data))
    }

    renderBillingLogsTable(logs){
      return (
        <table className="table">
          <tr>
            <th>{gettext("Billing type")}</th>
            <th>{gettext("Total")}</th>
            <th>{gettext("Date")}</th>
            <th></th>
          </tr>
          { logs.map((i) => {
            if ((i.billing_log_type == 'renewal' && i.transaction && i.transaction.state === 'settled') || i.billing_log_type == 'trial'){
              return (
                <>
                <tr>
                  <td>{gettext(i.billing_log_type)}</td>
                  { i.billing_log_type == 'renewal' ?
                    <>
                      <td>{i.transaction.amount} {i.transaction.currency}</td>
                      <td>{moment(i.billing_date).format('DD.MM.YYYY')}</td>
                      <td>
                        <a href={`/payments/transactions/${i.transaction.uuid}/receipt/`} target="_blank">{gettext("Receipt")}</a>
                      </td>
                    </>
                    :
                    <>
                      <td>{i.total}</td>
                      <td colSpan="3">{moment(i.billing_date).format('DD.MM.YYYY')}</td>
                    </>
                  }
                </tr>
              </>
              )
            }
          })
          }
        </table>
      )
    }

    getPaymentMethod(subscription){
      return (
        <>
        { subscription.data.customer &&
          <div>
            <div>{gettext("Card holder name")}: { subscription.data.customer.first_name } { subscription.data.customer.last_name }</div>
            <div>{gettext("Card")}: { subscription.data.customer.payment_method[0].display_info }</div>
            <div>{gettext("Valid until")}: { moment(subscription.data.customer.payment_method[0].valid_until).format("DD.MM.YYYY") }</div>
          </div>
        }
        </>
      )

    }

    render() {
        const { subscription, user } = this.props
        return (
            <>
            <div className="row justify-content-center subscription">
                <div className="col-16 col-sm-14 col-md-12 col-lg-8">
                    <h2>{gettext("My subscription")}</h2>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-16 col-md-6 col-lg-4 col-xl-3 information">
                    <div className="info-wrapper">
                    {user.hasSubscription ?
                      <>
                        { user.subscription.is_on_trial && 
                            <p>{gettext("Trial subscription")}. {gettext("Expires")}: {moment(user.subscription.active_until).format("D. M. YYYY")}</p>
                        }
                        { user.subscription.is_on_trial || user.subscription.active &&
                          <p>
                              { user.subscription.plan.name } ({ parseFloat(user.subscription.plan.amount) } { user.subscription.plan.currency })
                          </p>
                        }
                        { !user.subscription.active && moment(user.subscription.active_until) > moment() &&
                            <p>{gettext("Expires")}: {moment(user.subscription.active_until).format("D. M. YYYY")}</p>
                        }
                        { (user.subscription.active && !user.subscription.cancelled ) &&
                            <>
                                <p>{gettext("Next renewal")}: {moment(user.subscription.active_until).format("D. M. YYYY")}</p>
                                <button className="btn" onClick={this.cancelSubscription}>{gettext("Stop renewal")}</button>
                            </>
                        }
                        { (user.subscription.cancelled ) &&
                            <p>{gettext("Subscription is cancelled and expires")}: {moment(user.subscription.active_until).format("D. M. YYYY")}</p>
                        }
                        <div>{ this.getPaymentMethod(subscription) }</div>
                        <button className="btn mt-5" onClick={() => this.props.dispatch(openModal("modal-subscription"))}>{gettext('Update credit card')}</button>

                      </>
                    :
                      <>
                        <p>{gettext("No active subscription")}</p>
                        <button className="btn buy-button btn-buy" onClick={() => this.props.dispatch(openModal("modal-subscription"))}>{gettext("Buy subscription")}</button>
                      </>
                    }
                    </div>
                </div>

                <div className="col-16 col-md-6 col-lg-4 col-xl-5 options">
                    <div className="info-wrapper">
                    { subscription.data && subscription.data.customer && 
                        <>
                          <h3>{gettext("Transactions")}</h3>
                          <div>
                            <div className="form-container">
                              { this.renderBillingLogsTable(subscription.data.billing_logs)}
                            </div>
                          </div>
                        </>
                        }
                    </div>
                </div>
              </div>
            </>

        )

    }


}

export default Subscription;