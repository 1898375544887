import { fetchUser, userUpdateInfo } from '../../api/user/actions'

import ChordColorSelector from '../chord-color-selector'
import DarkmodeSelector from '../darkmode';
import EmailEdit from '../email-edit'
import InstrumentSelector from '../instrument-selector';
import ModalSubscription from '../modal-subscription';
import ModalUserSongs from '../modal-usersongs';
import ModalDeleteAccount from '../modal-delete-account';
import PasswordUpdate from '../password-update'
import React from 'react';
import SongLangEditor from '../song-lang-editor'
import SongSizeSelector from '../song-size-selector'
import { cancel } from '../../api/subscription/actions'
import moment from 'moment';
import { saveProfile } from '../../api/profile/actions'
import { updatePassword } from '../../api/password/actions'
import { openModal } from '../../api/modals/actions';

class Profile extends React.Component {

    constructor(props){
        super(props)
        this.state = {}

        this.changeUserEmail = this.changeUserEmail.bind(this);
        this.updateSongLangPreferences = this.updateSongLangPreferences.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.cancelSubscription = this.cancelSubscription.bind(this);
    }
    

    //Callback function from ChordColorSelector
    changeChordColor(color) {
        this.props.dispatch(saveProfile('chordColor', color, this.props.user.isAuthenticated))
    }

    //Callback function from SongSizeSelector
    changeFontSize(size) {
        this.props.dispatch(saveProfile('fontSize', size, this.props.user.isAuthenticated))
    }

    //Callback function from FontShowChords
    changeDarkmode(value) {
        let darkMode = value === 'dark' ? true : false
        this.props.dispatch(saveProfile('darkMode', darkMode, this.props.user.isAuthenticated))
    }

    changeInstrument(e) {
        let value = e.currentTarget.value
        this.props.dispatch(saveProfile('instrument_preference', value, this.props.user.isAuthenticated))
    }
    
    changeUserEmail(key, value){
        this.props.dispatch(userUpdateInfo(key, value))
    }

    updateSongLangPreferences(value) {
        this.props.dispatch(saveProfile('language_preference', value, this.props.user.isAuthenticated))

    }

    updatePassword(oldPw, newPw) {
        this.props.dispatch(updatePassword(oldPw, newPw, this.props.user.isAuthenticated));
    }

    cancelSubscription() {
        Promise.resolve(this.props.dispatch(cancel()))
        .then(() => {
            this.props.dispatch(fetchUser())
        })
    }
    

    render() {
        const { profile, instruments, uiPreference, instrumentPreference, user, songLang } = this.props
        return (
            <>
            <div className="row justify-content-center profile">
                <div className="col-16 col-sm-14 col-md-12 col-lg-8">
                    <div className="username-wrapper">
                            <div className="label">{gettext("Username")}</div>
                            <div className="username">{user.username}</div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-16 col-sm-7 col-md-6 col-lg-4 information">
                    <div className="info-wrapper">
                        <label>{gettext("Member since")}:</label>
                        <div className="info">{moment(user.date_joined).format('DD.MMMM YYYY')}</div>
                        <label>{gettext("Last login")}:</label>
                        <div className="info">{moment(user.last_login).format('DD.MMMM YYYY')}</div>
                        <label>{gettext("Songs sent in")}:</label>
                        <ModalUserSongs />
                        <button className="btn" onClick={() => this.props.dispatch(openModal("modal-usersongs"))}>{user.song_set_length} songs</button>
                    </div>
                    <div className="info-wrapper">
                        <label>{gettext("Email address")}:</label>
                        <EmailEdit field="email" placeholder={gettext("Email")} type="input" changeUserEmail={this.changeUserEmail} />
                    </div>
                    <div className="info-wrapper">
                        <PasswordUpdate updatePassword={this.updatePassword} passwordUpdate={this.props.passwordUpdate} />
                    </div>
                    <div className="info-wrapper">
                        <label>{gettext("Subscription")}:</label>
                        {user.hasSubscription ?
                          <>
                              { user.subscription.is_on_trial && 
                                  <p>{gettext("Trial subscription")}. {gettext("Expires")}: {moment(user.subscription.active_until).format("D. M. YYYY")}</p>
                              }
                              { (user.subscription.active ) &&
                                  <p>{gettext("Next renewal")}: {moment(user.subscription.active_until).format("D. M. YYYY")}</p>
                              }
                              { (user.subscription.cancelled ) &&
                                  <p>{gettext("Subscription is cancelled and expires")}: {moment(user.subscription.active_until).format("D. M. YYYY")}</p>
                              }
                              <a href="/subscription/">
                                <button className="btn">{gettext("My subscription")}</button>
                              </a>
                          </>
                        :
                          <>
                              <p>{gettext("No active subscription")}</p>
                              <button className="btn buy-button btn-buy" onClick={() => this.props.dispatch(openModal("modal-subscription"))}>{gettext("Buy subscription")}</button>
                          </>
                        }
                    </div>
                </div>

                <div className="col-16 col-sm-7 col-md-6 col-lg-4 options">
                    <div className="info-wrapper language">
                        <label>{gettext("Song language filter")} </label>
                        <SongLangEditor songLang={profile.ui_preferences.language_preference} allLang={songLang} updateSongLangPreferences={this.updateSongLangPreferences} />
                    </div>
                    <div className="info-wrapper">
                        <div className="control">
                            <p>{gettext("Instrument")}</p>
                            <InstrumentSelector onChange={this.changeInstrument.bind(this)} instruments={instruments} instrumentPreference={instrumentPreference} />
                        </div>
                    </div>
                    <div className="info-wrapper">
                        <div className="control view-mode">
                            <p>{gettext("Theme")}</p>
                            <DarkmodeSelector onChange={this.changeDarkmode.bind(this)} darkMode={this.props.uiPreference.darkMode} user={this.props.user} dispatch={this.props.dispatch}/>
                        </div>
                    </div>
                    <div className="info-wrapper">
                        <div className="control view-mode">
                            <p>{gettext("Font size")}</p>
                            <SongSizeSelector fontSize={this.props.uiPreference.fontSize} onChange={this.changeFontSize.bind(this)} user={this.props.user} dispatch={this.props.dispatch}/>
                        </div>
                    </div>
                    <div className="info-wrapper">
                        <div className="control view-mode">
                            <p>{gettext("Chord color")}</p>
                            <ChordColorSelector chordColor={this.props.uiPreference.chordColor} onChange={this.changeChordColor.bind(this)} user={this.props.user} dispatch={this.props.dispatch}/>
                        </div>
                    </div>
                    <div className="info-wrapper delete">
                        <div className="control delete-account">
                            <ModalDeleteAccount/>
                            <button className="btn" onClick={() => this.props.dispatch(openModal("modal-delete-account"))} title="Here you can permanatly delete your account">{gettext('Delete account')}</button>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )

    }


}

export default Profile;